<template>
  <div id="app">
    <functionReview :menu-id="menuId" />
    <router-view />
  </div>
</template>

<script>
import functionReview from '../moduleFunctions/functionReview/index.vue';
export default {
  name: 'App',
  components: {
    functionReview,
  },
  data() {
    return {};
  },
  computed: {
    menuId() {
      return this.$route.meta.menuId;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
a {
  text-decoration: none;
}
.el-table__cell .cell:empty::before {
  content: '--';
  color: gray;
}
#app .sidebar-container {
  -webkit-transition: width 0.28s;
  transition: width 0.28s;
  background-color: #fff;
  height: 100%;
  font-size: 0px;
  z-index: 1001;
  overflow: hidden;
  .el-menu {
    border-right: 0;
  }
}
</style>
